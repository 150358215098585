<template>
  <div>
    <section id="login" class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <div class="brand mb-3">
          <h3><small>DUKE CLUB</small><br>CREATE ACCOUNT</h3>
        </div>

        <!-- form -->
        <div class="form">

          <!-- user type selection -->
          <div class="form-row">
            <label for="type">Type</label>
            <select name="type" class="form-control" id="type" v-model="userData.type">
              <option v-for="(type, index) in typeNames" :key="index" :value="index">{{ type }}</option>
            </select>
          </div>


          <div class="form-row">
            <!-- first name -->
            <div class="col">
              <p class="p4"><i class="fal fa-user"></i> First Name</p>
              <input type="text" class="input" placeholder="" v-model="userData.firstName" v-bind:class="{
                'form-control': true,
                'is-invalid': !validName(userData.firstName) && bluredFirstName
              }" v-on:blur="bluredFirstName = true" />
              <div class="invalid-feedback">
                Please enter your first name.
              </div>

            </div>

            <!-- last name -->
            <div class="col">
              <p class="p4"><i class="fal fa-user"></i> Last Name</p>
              <input type="text" class="input" placeholder="" v-model="userData.lastName" v-bind:class="{
                'form-control': true,
                'is-invalid': !validName(userData.lastName) && bluredLastName
              }" v-on:blur="bluredLastName = true" />
              <div class="invalid-feedback">
                Please enter your first name.
              </div>
            </div>
          </div>

          <!-- email -->
          <div class="form-group">
            <div class="form-row">
              <div class="col">
                <p class="p4"><i class="fal fa-envelope"></i> Email</p>
                <input type="email" class="input" placeholder="" v-model="userData.email" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validEmail(userData.email) && bluredEmail
                }" v-on:blur="bluredEmail = true" />
                <div class="invalid-feedback">
                  Please enter a valid email.
                </div>
              </div>

              <!-- phone -->
              <div class="col">
                <p class="p4"><i class="fal fa-phone-alt"></i> Phone</p>
                <input type="text" class="input" placeholder="" v-model="userData.tel" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validPhone(userData.tel) && bluredTel
                }" v-on:blur="bluredPhone = true" />
                <div class="invalid-feedback">
                  Please enter a valid phone number.
                </div>
              </div>
            </div>
          </div>

          <!-- address -->
          <div class="form-group">
            <div class="form-row">
              <div class="col">
                <p class="p4"><i class="fal fa-house"></i> Address</p>
                <input type="text" class="input" placeholder="" v-model="userData.addr" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(userData.addr) && bluredAddress
                }" v-on:blur="bluredAddress = true" />
                <div class="invalid-feedback">
                  Please enter your address.
                </div>
              </div>

              <!-- zip -->
              <div class="col">
                <p class="p4"><i class="fal fa-map-marker-alt"></i> Zip</p>
                <input type="text" class="input" placeholder="" v-model="userData.zip" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(userData.zip) && bluredZip
                }" v-on:blur="bluredZip = true" />
                <div class="invalid-feedback">
                  Please enter your zip code.
                </div>
              </div>
            </div>
          </div>

          <!-- city -->
          <div class="form-group">
            <div class="form-row">
              <div class="col">
                <p class="p4"><i class="fal fa-city"></i> City</p>
                <input type="text" class="input" placeholder="" v-model="userData.city" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(userData.city) && bluredCity
                }" v-on:blur="bluredCity = true" />
                <div class="invalid-feedback">
                  Please enter your city.
                </div>
              </div>

              <!-- country -->
              <div class="col">
                <p class="p4"><i class="fal fa-globe-europe"></i> Country</p>
                <b-form-select class="input-select" :options="countriesList" v-model="userData.country"></b-form-select>
              </div>
            </div>
          </div>

          <!-- press id -->
          <div v-if="userIsPress" class="form-group">
            <p class="p4"><i class="fal fa-id-card-alt"></i> Press ID</p>
            <input type="text" class="input" placeholder="" v-model="companyData.pressId" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(companyData.pressId) && bluredPressId
            }" v-on:blur="bluredPressId = true" />
            <div class="invalid-feedback">
              Please enter your press ID.
            </div>
          </div>
          <!-- birthday -->
          <div v-else class="form-group">
            <p for="email" class="p4"><i class="fal fa-calendar-alt"></i> Date of Birth</p>
            <input type="date" class="input" placeholder="" v-model="userData.birthday" />
          </div>

          <!-- password -->
          <div class="form-group">
            <div class="form-row">
              <div class="col">
                <p for="password" class="p4"><i class="fal fa-lock"></i> Password</p>
                <input type="password" class="input" placeholder="" v-model="password" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validPassword(password) && bluredPassword
                }" v-on:blur="bluredPassword = true" />
                <div class="invalid-feedback">
                  Your password must be at least 8 charachters long.
                </div>
              </div>

              <!-- confirm password -->
              <div class="form-group">
                <p for="password" class="p4"><i class="fal fa-lock"></i> Confirm Password</p>
                <input type="password" class="input" placeholder="" v-model="passwordCheck" v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    !validPasswordMatch(password, passwordCheck) && bluredPasswordCheck
                }" v-on:blur="bluredPasswordCheck = true" />
                <div class="invalid-feedback">
                  Passwords don't match.
                </div>
              </div>
            </div>
          </div>

          <div v-if="userIsPress">
            <!-- COMPANY -->
            <h4 class="mt-4 mb-4 grid-2"><i class="fal fa-building"></i> Company</h4>

            <!-- company name -->
            <div class="form-group">
              <div class="form-row">
                <div class="col">
                  <p class="p4"><i class="fal fa-building"></i> Name</p>
                  <input type="text" class="input" placeholder="" v-model="companyData.name" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validName(companyData.name) && bluredCompanyName
                  }" v-on:blur="bluredCompanyName = true" />
                  <div class="invalid-feedback">
                    Please enter the name of your company.
                  </div>
                </div>

                <!-- company email -->
                <!-- <div class="form-group">
            <p class="p4"><i class="fal fa-envelope"></i> Email</p>
            <input type="text" class="input" placeholder="" v-model="companyData.email" v-bind:class="{
                'form-control': true,
                'is-invalid': !validEmail(companyData.email) && bluredCompanyEmail
              }"
              v-on:blur="bluredCompanyEmail = true"
            />
            <div class="invalid-feedback">
              Please enter your name.
            </div>
          </div> -->

                <!-- company phone -->
                <div class="col">
                  <p class="p4"><i class="fal fa-phone-alt"></i> Phone</p>
                  <input type="text" class="input" placeholder="" v-model="companyData.phone" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validPhone(companyData.phone) && bluredCompanyPhone
                  }" v-on:blur="bluredCompanyPhone = true" />
                  <div class="invalid-feedback">
                    Please enter the phone number of your company.
                  </div>
                </div>
              </div>
            </div>

            <!-- company address -->
            <div class="form-group">
              <div class="form-row">
                <div class="col">
                  <p class="p4"><i class="fal fa-building"></i> Address</p>
                  <input type="text" class="input" placeholder="" v-model="companyData.addr" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(companyData.addr) && bluredCompanyAddress
                  }" v-on:blur="bluredCompanyAddress = true" />
                  <div class="invalid-feedback">
                    Please enter the address of your company.
                  </div>
                </div>

                <!-- company zip -->
                <div class="col">
                  <p class="p4"><i class="fal fa-map-marker-alt"></i> Zip</p>
                  <input type="text" class="input" placeholder="" v-model="companyData.zip" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(companyData.zip) && bluredCompanyZip
                  }" v-on:blur="bluredCompanyZip = true" />
                  <div class="invalid-feedback">
                    Please enter the zip code of your company.
                  </div>
                </div>
              </div>
            </div>

            <!-- company city -->
            <div class="form-group">
              <div class="form-row">
                <div class="col">
                  <p class="p4"><i class="fal fa-city"></i> City</p>
                  <input type="text" class="input" placeholder="" v-model="companyData.city" v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validInput(companyData.city) && bluredCompanyCity
                  }" v-on:blur="bluredCompanyCity = true" />
                  <div class="invalid-feedback">
                    Please enter the city of your company.
                  </div>
                </div>

                <!-- company country -->
                <div class="col">
                  <p class="p4"><i class="fal fa-globe-europe"></i> Country</p>
                  <b-form-select class="input-select" :options="countriesList"
                    v-model="companyData.country"></b-form-select>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="btn-container text-center mt-4 mb-4">
          <button class="jbtn jbtn-blue" value="Add" @click="registerUser">
            Create
          </button>
        </div>

        <div class="login-message">
          <p>{{ validationMsg }}</p>
          <p>{{ pwNotOkMsg }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import C from "@/const";
import CL from "@/countries";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

// Secondary firebase auth instance
// this is necessary to allow admin to stay logged in while simultaneously be logged in as newly created user after createUserWithEmailAndPassword
import 'firebase/auth';
import firebaseForSignup from 'firebase/app';
import configDev from "@/firebase/firebaseConfigDev";
import config from "@/firebase/firebaseConfig";
const firebaseConfig = C.PRODUCTION ? config : configDev;
const firebaseAppForSignup = firebaseForSignup.initializeApp(firebaseConfig, "Signup");
const authForSignup = firebaseAppForSignup.auth();
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "CreateUser",
  data() {
    return {
      title: "Create account",
      typeNames: C.TYPE_INDEX,
      password: "",
      passwordCheck: "",

      isSaving: false,
      //form error handling
      valid: false,
      validCompany: false,
      showInputError: false,
      validationMsg: "",
      pwNotOkMsg: "",
      // blured inputs
      bluredFirstName: false,
      bluredLastName: false,
      bluredEmail: false,
      bluredTel: false,
      bluredAddress: false,
      bluredZip: false,
      bluredCity: false,
      bluredCountry: false,
      bluredPassword: false,
      bluredPasswordCheck: false,
      // blured press
      bluredPressId: false,
      bluredCompanyName: false,
      // bluredCompanyEmail: false,
      bluredCompanyPhone: false,
      bluredCompanyAddress: false,
      bluredCompanyZip: false,
      bluredCompanyCity: false,
      bluredCompanyCountry: false,

      companyData: {
        pressId: "",
        name: "",
        email: "",
        phone: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg"
      },
      userData: {
        createdOn: serverTimestamp,
        firstName: "",
        lastName: "",
        email: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg",
        tel: "",
        type: C.TYPE.PRESS,
        status: C.STATUS.PRESS_VERIFICATON.VERIFIED,
      },
      countriesList: CL.COUNTRIES

    };
  },
  methods: {
    registerUser() {
      // TODO: MORE INPUT VALIDATION

      if (this.validate()) {
        this.validationMsg = "SUCCESS";
        authForSignup
          .createUserWithEmailAndPassword(
            //create auth user
            this.userData.email,
            this.password
          )
          .then(() => {
            //update auth user
            authForSignup
              .currentUser.updateProfile({
                displayName:
                  this.userData.firstName + " " + this.userData.lastName,
              })
              .then(() => {
                //create user doc
                if (this.userIsPress) {
                  this.userData.company = this.companyData;
                }
                db.collection(C.COLLECTION.USERS)
                  .doc(authForSignup.currentUser.uid)
                  .set(this.userData)
                  .then(() => {
                    const uid = authForSignup.currentUser.uid;
                    console.log("assigning role for user: " + uid);
                    console.log("type: " + this.userData.type);
                    // create (claims) process doc in order to trigger fiebase function that will update the claims
                    const claimsUpdate = {
                      userId: uid,
                      role: this.userData.type
                    };
                    if (this.userIsPress)
                      claimsUpdate.status = C.STATUS.PRESS_VERIFICATON.VERIFIED;

                    db.collection(C.COLLECTION.CLAIMS_PROCESS).add(claimsUpdate);
                    authForSignup.signOut();
                  })
                  .then(() => {
                    this.pwNotOkMsg = "";
                    this.$router.push({ name: "UsersList" });
                  })
                  .catch((err) => {
                    authForSignup.signOut();
                    this.pwNotOkMsg = "COULD NOT CREATE DOCUMENT";
                    this.validationMsg = err.message;
                  });
              })
              .catch((err) => {
                authForSignup.signOut();
                this.pwNotOkMsg = "COULD NOT UPDATE PROFILE";
                this.validationMsg = err.message;
              });
          })
          .catch((err) => {
            this.pwNotOkMsg = "COULD NOT CREATE USER";
            this.validationMsg = err.message;
          });
        this.pwNotOkMsg = "registration in progress";
      } else {
        this.validationMsg = "Please complete the form";
      }
    },
    validate() {
      // Check name
      this.bluredFirstName = true;
      if (!this.validName(this.userData.firstName)) {
        this.valid = false;
        return false;
      } // Check name
      this.bluredLastName = true;
      if (!this.validName(this.userData.lastName)) {
        this.valid = false;
        return false;
      }
      // Check email
      this.bluredEmail = true;
      this.userData.email = this.userData.email.trim().toLowerCase();
      if (!this.validEmail(this.userData.email)) {
        this.valid = false;
        return false;
      }
      // Check phone
      this.bluredTel = true;
      if (!this.validPhone(this.userData.tel)) {
        this.valid = false;
        return false;
      }
      // Check address
      this.bluredAddress = true;
      if (!this.validInput(this.userData.addr)) {
        this.valid = false;
        return false;
      }
      // Check zip
      this.bluredZip = true;
      if (!this.validInput(this.userData.zip)) {
        this.valid = false;
        return false;
      }
      // Check city
      this.bluredCity = true;
      if (!this.validInput(this.userData.city)) {
        this.valid = false;
        return false;
      }
      // Check birthday: TODO!

      //Check password
      this.bluredPassword = true;
      if (!this.validPassword(this.password)) {
        this.valid = false;
        this.pwNotOkMsg = "Your password is too short (8 characters min.)";
        return false;
      }
      //Check confirm password
      this.bluredPasswordCheck = true;
      if (!this.validPasswordMatch(this.password, this.passwordCheck)) {
        this.valid = false;
        this.pwNotOkMsg = "Your passwords did not match.";
        return false;
      }

      if (this.userIsPress && ! this.validateCompany())
        return false;

      this.pwNotOkMsg = "";
      this.valid = true;
      return true;
    },
    validateCompany() {
      // Check press id
      this.bluredPressId = true;
      if (!this.validInput(this.companyData.pressId)) {
        this.valid = false;
        return false;
      }
      // Check company name
      this.bluredCompanyName = true;
      if (!this.validName(this.companyData.name)) {
        this.valid = false;
        return false;
      }
      // Check company email
      // this.bluredCompanyEmail = true;
      // this.companyData.email = this.companyData.email.trim().toLowerCase();
      // if (!this.validEmail(this.companyData.email)) {
      //   this.valid = false;
      //   return false;
      // }
      // Check company phone
      this.bluredCompanyPhone = true;
      if (!this.validPhone(this.companyData.phone)) {
        this.valid = false;
        return false;
      }
      // Check company address
      this.bluredCompanyAddress = true;
      if (!this.validInput(this.companyData.addr)) {
        this.valid = false;
        return false;
      }
      // Check company zip
      this.bluredCompanyZip = true;
      if (!this.validInput(this.companyData.zip)) {
        this.valid = false;
        return false;
      }
      // Check company city
      this.bluredCompanyCity = true;
      if (!this.validInput(this.companyData.city)) {
        this.valid = false;
        return false;
      }

      this.validCompany = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validName(firstName) {
      return firstName.trim().length > 2;
    },
    validPhone(phone) {
      return phone ? phone.trim().length > 5 : false;
    },
    validPassword(password) {
      return password.trim().length > 7;
    },
    validPasswordMatch(password, passwordCheck) {
      return !(password != passwordCheck);
    },
    checkEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    checkPhone: function (phone) {
      var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
      return re.test(String(phone).toLowerCase());
    },
  },
  computed: {
    userIsPress: {
      get() {
        return this.userData.type == C.TYPE.PRESS;
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>
